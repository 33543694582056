export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    {
      label: "跳转第三方",
      prop: "miniProgram",
      slots: "miniProgram",
      width: 120,
    },
    { label: "所属门店", prop: "store_id", slots: "store_id" },
    { label: "商品ID", prop: "id" , width: 80},
    { label: "商品编码", prop: "productCode" },
    { label: "产品名", prop: "productName" },
    { label: "价格", prop: "price", width: 130 },
    { label: "状态", prop: "status", slots: "status" },
    { label: "显示规格", prop: "isSku", slots: "isSku", width: 120 },
    {
      label: "创建时间",
      prop: "createTime",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 180,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
